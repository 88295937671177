<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="row" id="table-hover-row  mb-1">
      <div class="col-12">
        <core-card [actions]="[]" [reloadTime]="'1000'">
          <h4 class="card-title">
            <div *ngIf="location != undefined">Liste des mesures ( {{ location.name }} )</div>
            <div (click)="previousState()">
              <i data-feather="arrow-left" class="mr-50 cursor-style"></i>
            </div>
          </h4>
          <div *ngIf="measures && measures.length === 0" class="no-data">
            <div class="no-data-text">
              Aucune donnée trouvée
            </div>
          </div>
          <div class="table-responsive" *ngIf="measures && measures.length > 0">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Dernière mesure</th>
                  <th>Mesure minimale</th>
                  <th>Mesure maximale</th>
                  <th>Unité</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let measure of measures">
                  <td>{{ measure.name }}</td>
                  <td>{{ (measure.lastValue == undefined) ? '-' :  measure.lastValue }}</td>
                  <td>{{ (measure.minValue == undefined) ? 'Non défini' :  measure.minValue }}</td>
                  <td>{{ (measure.maxValue == undefined) ? 'Non défini' :  measure.maxValue }}</td>
                  <td>{{ (measure.unit == undefined) ? 'Non défini' :  measure.unit }}</td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem (click)="updateMeasure(measure)">
                          <i data-feather="edit-2" class="mr-50"></i>
                          <span>Modifier</span>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </core-card>
      </div>
    </div>
  </div>
</div>