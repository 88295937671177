import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { ITEMS_PER_PAGE } from 'app/config/pagination.constants';
import { IDevice } from 'app/models/device.model';
import { ILocation } from 'app/models/location.model';
import { IMeasure, Measure } from 'app/models/measure.model';
import { DeviceService } from 'app/services/device.service';
import { MeasureUpdateDialogService } from 'app/services/measure-update-dialog.service';
import { MeasureService } from 'app/services/measure.service';

@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MeasureComponent implements OnInit {
  location: ILocation | null = null;
  devices?: IDevice[];
  measures?: IMeasure[] = [];

  isLoading = false;
  totalItems = 0;
  itemsPerPage = ITEMS_PER_PAGE;
  page?: number;
  predicate!: string;
  ascending!: boolean;
  ngbPaginationPage = 1;

  private modalRef?: Promise<void>;

  constructor(
    protected deviceService: DeviceService,
    protected measureService: MeasureService,
    private measureUpdateDialogService: MeasureUpdateDialogService,
    protected activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ location }) => {
      this.location = location;

      if (this.location != undefined && this.location.id != undefined) {

        this.deviceService
          .findAllByByLocationId(
            {
              size: 500,
            },
            location.id
          )
          .subscribe(
            {
              next: async (res: HttpResponse<IDevice[]>) => {
                this.isLoading = false;

                location.devices = res.body ?? [];

                const promises = location.devices.map(async (device: any) => this.measureService
                  .findAllByByDeviceId(
                    {
                      size: 500,
                    },
                    device.id
                  )
                  .subscribe(
                    {
                      next: (res: HttpResponse<IMeasure[]>) => {
                        res.body.map(measure => {
                          this.measures.push(measure);
                        });
                      },
                      error: () => { },
                    }
                  ));

                await Promise.all(promises);
              },
              error: () => { },
            }
          );
      }
    });
  }

  previousState(): void {
    window.history.back();
  }

  updateMeasure(event: any): void {
    this.modalRef = this.measureUpdateDialogService.open(event).result.then(
      () => {
        this.measures = [];
        this.ngOnInit();
      },
      () => {
        this.measures = [];
        this.ngOnInit();
      }
    );
  }
}
