import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import { CoreConfigService } from '@core/services/config.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { colors } from 'app/colors.const';
import { DataStoreService } from 'app/services/data-store.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('fmiChartRef') fmiChartRef: any;
  @ViewChild('firstMeasureChartRef') firstMeasureChartRef: any;
  @ViewChild('secondMeasureChartRef') secondMeasureChartRef: any;
  @ViewChild('thirdMeasureChartRef') thirdMeasureChartRef: any;
  @ViewChild('fourthMeasureChartRef') fourthMeasureChartRef: any;

  public fmiChartOptions: any;
  public firstMeasureChartOptions: any;
  public secondMeasureChartOptions: any;
  public thirdMeasureChartOptions: any;
  public fourthMeasureChartOptions: any;

  private fmiPrimaryStrokeColor = '#7368F0';
  private fmiSecondaryStrokeColor = '#C7C3F9';

  private firstMeasurePrimaryStrokeColor = '#fff';
  private firstMeasureSecondaryStrokeColor = '#5e5873';

  private secondMeasurePrimaryStrokeColor = '#00B2CE';
  private secondMeasureSecondaryStrokeColor = '#85EEFF';

  private thirdMeasurePrimaryStrokeColor = '#28C76F';
  private thirdMeasureSecondaryStrokeColor = '#A5EDC5';

  private fourthMeasurePrimaryStrokeColor = '#FF9F43';
  private fourthMeasureSecondaryStrokeColor = '#FFD9B4';

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  constructor(public dataStore: DataStoreService, private cdref: ChangeDetectorRef, private _coreConfigService: CoreConfigService) {
    // FMI Chart
    this.fmiChartOptions = {
      chart: {
        type: 'donut',
        height: 120,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [36, 64],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ['Rempli', 'Vide'],
      stroke: { width: 0 },
      colors: [this.fmiPrimaryStrokeColor, this.fmiSecondaryStrokeColor],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val) + '%';
                }
              },
              total: {
                show: true,
                offsetY: 15,
                label: '/400',
                formatter: function (w) {
                  return '145';
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120
            }
          }
        },
        {
          breakpoint: 1065,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    };

    // First Measure Chart
    const maxValue = 50;
    const minValue = 0;

    this.firstMeasureChartOptions = {
      chart: {
        height: 290,
        type: 'radialBar',
        sparkline: {
          enabled: false
        }
      },
      plotOptions: {
        radialBar: {
          offsetY: 20,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '65%'
          },
          track: {
            background: this.firstMeasurePrimaryStrokeColor,
            strokeWidth: '100%'
          },
          dataLabels: {
            name: {
              offsetY: -5,
              color: this.firstMeasureSecondaryStrokeColor,
              fontSize: '1rem'
            },
            value: {
              offsetY: 15,
              color: this.firstMeasureSecondaryStrokeColor,
              fontSize: '1.714rem',
              formatter: function (val: number) {
                return (val * (maxValue - minValue)) / 100 + minValue + ' °C';
              }
            }
          }
        }
      },
      colors: [colors.solid.danger],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [colors.solid.primary],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [70, 100]
        }
      },
      stroke: {
        dashArray: 8
      },
      labels: ['Température de l\'eau']
    };

    // Second Measure Chart
    this.secondMeasureChartOptions = {
      chart: {
        type: 'donut',
        height: 100,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [67, 33],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ['H2O', 'H2O'],
      stroke: { width: 0 },
      colors: [this.secondMeasurePrimaryStrokeColor, this.secondMeasureSecondaryStrokeColor],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val: string) {
                  return parseInt(val) + '%';
                }
              },
              total: {
                show: true,
                offsetY: 15,
                label: 'H2O',
                formatter: function (w: any) {
                  return '67%';
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120
            }
          }
        },
        {
          breakpoint: 1065,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    };

    // Third Measure Chart
    this.thirdMeasureChartOptions = {
      chart: {
        type: 'donut',
        height: 100,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [40, 60],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ['Cl', 'Cl'],
      stroke: { width: 0 },
      colors: [this.thirdMeasurePrimaryStrokeColor, this.thirdMeasureSecondaryStrokeColor],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val: string) {
                  return parseInt(val) + '%';
                }
              },
              total: {
                show: true,
                offsetY: 15,
                label: 'Cl',
                formatter: function (w: any) {
                  return '2.3';
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120
            }
          }
        },
        {
          breakpoint: 1065,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    };

    // Fourth Measure Chart
    this.fourthMeasureChartOptions = {
      chart: {
        type: 'donut',
        height: 100,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [47, 53],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ['pH', 'PH'],
      stroke: { width: 0 },
      colors: [this.fourthMeasurePrimaryStrokeColor, this.fourthMeasureSecondaryStrokeColor],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val: string) {
                  return parseInt(val) + '%';
                }
              },
              total: {
                show: true,
                offsetY: 15,
                label: 'pH',
                formatter: function (w: any) {
                  return '7.4';
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120
            }
          }
        },
        {
          breakpoint: 1065,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    };
  }

  ngOnInit() { 
  
    this.loadScriptByUrl('https://weatherwidget.io/js/widget.min.js');
  }

  loadScriptByUrl(url: string) {
    let dynamicScript = document.createElement('script');
    dynamicScript.type = 'text/javascript';
    dynamicScript.async = true;
    dynamicScript.src = url;
    document.body.appendChild(dynamicScript);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  /**
 * After View Init
 */
  ngAfterViewInit() {
    // Subscribe to core config changes
    this._coreConfigService.getConfig().subscribe(config => {
      // If Menu Collapsed Changes
      if (config.layout.menu.collapsed === true || config.layout.menu.collapsed === false) {
        setTimeout(() => {
          // Get Dynamic Width for Charts
          this.firstMeasureChartOptions.chart.width = this.firstMeasureChartRef?.nativeElement.offsetWidth;
          this.secondMeasureChartOptions.chart.width = this.secondMeasureChartRef?.nativeElement.offsetWidth;
          this.thirdMeasureChartOptions.chart.width = this.thirdMeasureChartRef?.nativeElement.offsetWidth;
          this.fourthMeasureChartOptions.chart.width = this.fourthMeasureChartRef?.nativeElement.offsetWidth;
          this.fmiChartOptions.chart.width = this.fmiChartRef?.nativeElement.offsetWidth;
        }, 1000);
      }
    });
  }
}
